<template>
   <div>
        <div class="justify-content-center mt-2 ml-4 mr-4 mb-2">
            <h2 class="black-text bold mb-4">Session Timeout</h2>
            <h3 class="black-text mb-4"> <v-icon style="cursor: pointer;color: #000000;font-size: 21px;">info</v-icon> Warning</h3>
            <p class="contact-text ml-5 mb-5">
                You login session has timed out. Please login again.
            </p>
            <b-button @click="logout" class="btn mt-3 btn-customize">Go to login page</b-button>
        </div>
    </div>     
</template>

<script>
import { removeAuthenticatedUserByCache} from "@/helpers";
export default {
  name: 'LogoutModal',
  data () {
    return {
        logout() {
           removeAuthenticatedUserByCache();
           window.location.href = '/auth/login';
        }
    }
  },
  methods: {}
}
</script>

<style scoped>
    .bold{
        font-weight: bold;
        font-size: 20px im !important;
    }
    .black-text{
        color: #000000;
        font-size: 21px;
    }

    a.black-text{
        color: #000000;
        font-weight: bold;
        text-decoration: underline;
    }
    .btn-customize{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: #fff !important;
        padding: 5px;
        font-size: 15px;
        width: 35%;
        float: right;
    }

    .btn-customize:active{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: #fff !important;
        padding: 5px;
        font-size: 15px;
        width: 35%;
        float: right;
    }

    .contact-text{
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }
</style>
