import { rejectError, saveAuthenticatedUserToCache } from "@/helpers";
import axiosInstance from '../../services';
import moment from 'moment'
import { getAuthenticatedUserByCache } from "@/helpers";

export default {
  namespaced: true,
  state: {
    users: [],
    userDetail: {
      privilages: []
    },
    deleteUserResponse: {},
    resetPasswordResponse: {},
    createUser: {},
    updateUser: {},
    enabled2Fa: {},
    twoFactorUsers: [],
    isEnabled2FA: false,
    deleteOwn2Fa: {},
    deleteAdmin2Fa: {},
    verify2Fa: {},

    show2FAModal: false,
    showEnable2FAModal: false,
    twoFAFormData: {},
    twoFASubmitCallback: null,
  },
  getters: {
    users(state) {
      if (state.users) {
        const { totalElements, content } = state.users;
        if (content) {
          content.forEach(element => {
            element.createdAt = moment(element.createdAt).format('DD-MM-YYYY, h:mm:ss a');
          });
        }
        return { totalElements, content };
      }
      return null;
    },
    getUserLogs(state) {
      return state.userLogs;
    },
    getUserDetail(state) {
      if (state.userDetail) {
        return state.userDetail;
      }
      return null;
    },
    getDeleteUser(state) {
      return state.deleteUserResponse;
    },
    getCreateUser(state) {
      return state.createUser;
    },
    getUpdateUser(state) {
      return state.updateUser;
    },
    getResetPassword(state) {
      return state.resetPasswordResponse;
    },
    getEnabled2Fa(state) {
      return state.enabled2Fa;
    },
    get2FAUsers(state) {
      return state.twoFactorUsers;
    },
    isEnabled2FA(state) {
      return state.isEnabled2FA;
    },
    getDeleteOwn2FA(state) {
      return state.deleteOwn2Fa;
    },
    getDeleteAdmin2FA(state) {
      return state.deleteAdmin2Fa;
    },
    getVerify2Fa(state) {
      return state.verify2Fa;
    },
    get2FAFormData(state) {
      return state.twoFAFormData;
    },
    get2FASubmitCallback(state) {
      return state.twoFASubmitCallback;
    }
  },
  actions: {
    apiDoc() {
      return axiosInstance.get(`/merchants/panel/api-doc`)
        .then(res => {
          const response = res.data;
          return response;
        })
        .catch(err => rejectError(err))
    },
    getUsers({ commit }, data) {
      const { page } = data
      return axiosInstance.get(`/merchants/merchantuser/panel/list?page=${page}`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            commit('setUsers', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    deleteUser({ commit }, data) {
      const { id } = data

      return axiosInstance.delete(`/merchants/merchantuser/panel/${id}`)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }

            commit('setDeleteUser', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    createUser({ commit }, data) {

      return axiosInstance.post(`/merchants/merchantuser/panel/`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }

            commit('setCreateUser', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    updateUser({ commit }, data) {
      const { id } = data;
      return axiosInstance.put(`/merchants/merchantuser/panel/${id}`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }

            commit('setUpdateUser', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    getUserDetail({ commit }, data) {
      const { userId } = data

      return axiosInstance.get(`/merchants/merchantuser/panel/detail/${userId}`)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            commit('setUserDetail', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    getUserLogs({ commit }, data) {

      return axiosInstance.post(`/activity/panel/activity-log/logs?page=${data.page}&size=${data.limit}&sort=processDate,desc`, data.filters)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            commit('setUserLogs', response.data);
            return response.data;
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    resetPassword({ commit }, data) {
      const userId = data.UUID;
      return axiosInstance.put(`/merchants/merchantuser/panel/reset-password/${userId}`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }

            commit('setResetPassword', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    enabled2Fa({ commit }) {
      return axiosInstance.post(`/merchants/user/panel/twoFactor`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            commit('setEnabled', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    get2FAUsers({ commit }, data) {

      return axiosInstance.get(`/merchants/user/panel/twoFactor/users`, {})
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            commit('set2FAUsers', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    setIsEnabled2FA({ commit }, data) {
      let twoFaCode = data.twoFaCode;
      return axiosInstance.put(`/merchants/user/panel/twoFactor`, { twoFaCode, status: true })
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            commit('setIsEnabled2FA', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    deleteOwn2FA({ commit }) {
      return axiosInstance.delete(`/merchants/user/panel/twoFactor`, {})
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            commit('setDeleteOwn2Fa', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    deleteAdmin2FA({ commit }, data) {
      let id = data.id;
      return axiosInstance.post(`/merchants/user/panel/twoFactor/delete`, { id })
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            commit('setDeleteAdmin2Fa', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    verify2Fa({ commit }, data) {
      return axiosInstance.post(`/merchants/user/panel/twoFactor/verify`, data)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            let authUser = getUserFromToken(response);
            authUser.isRefleshToken = "false";
            saveAuthenticatedUserToCache(authUser);
            commit('setVerify2Fa', response.data)
          }
          else {
            rejectError("there is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    show2FAModal({ state, getters, commit, dispatch }, formData) {

      let authenticatedUser = getAuthenticatedUserByCache();

      if (authenticatedUser.merchant2FaEnabled === 'true') {
        commit('set2FAFormData', formData)
        commit('setShow2FAModal', true)
      } else {
        commit('setShowEnable2FAModal', true)
      }
    }
  },
  mutations: {
    clearUserList(state) {
      return state.users = []
    },
    setUsers(state, users) {
      return state.users = users
    },
    setUserDetail(state, response) {
      return state.userDetail = response
    },
    setUserLogs(state, response) {
      return state.userLogs = response
    },
    setDeleteUser(state, response) {
      return state.deleteUserResponse = response
    },
    setCreateUser(state, response) {
      return state.createUser = response
    },
    setUpdateUser(state, response) {
      return state.updateUser = response
    },
    setResetPassword(state, response) {
      return state.resetPasswordResponse = response
    },
    setEnabled(state, response) {
      return state.enabled2Fa = response
    },
    set2FAUsers(state, response) {
      return state.twoFactorUsers = response
    },
    setIsEnabled2FA(state, response) {
      return state.isEnabled2FA = response
    },
    setDeleteOwn2Fa(state, response) {
      return state.deleteOwn2Fa = response
    },
    setDeleteAdmin2Fa(state, response) {
      return state.deleteAdmin2Fa = response
    },
    setVerify2Fa(state, response) {
      return state.verify2Fa = response
    },
    setShow2FAModal(state, response) {
      return state.show2FAModal = response
    },
    setShowEnable2FAModal(state, response) {
      return state.showEnable2FAModal = response
    },
    set2FAFormData(state, response) {
      return state.twoFAFormData = response
    },
    set2FASubmitCallback(state, response) {
      return state.twoFASubmitCallback = response
    }
  }
}

function getUserFromToken(response) {
  const merchantPanelToken = response.data.accessToken;
  const merchantPanelRefleshToken = response.data.refreshToken;
  const usageType = response.data.usageType;
  let authUser = JSON.parse(atob(merchantPanelToken.split('.')[1]));
  const user = {
    email: authUser.email,
    userName: authUser.preferred_username,
    firstName: authUser.given_name,
    lastName: authUser.family_name,
    roles: authUser.roles,
    uuid: authUser.sub,
    exp: authUser.exp,
    merchantId: authUser.merchant_id,
    userVerified: response.userVerified,
    merchant2FaEnabled: "true",
    merchant2FaVerified: "true",
  };
  return { ...user, merchantPanelToken, merchantPanelRefleshToken, usageType };
}
