<template>
    <div>
        <div class="justify-content-center justify-content-center mt-2 ml-4 mr-4 mb-2">
            <h2 class="black-text mb-4 bold">Session Timeout </h2>
            <h3 class="black-text mb-4"> <v-icon style="cursor: pointer;color: #000000;font-size: 21px;">info</v-icon> Warning</h3>
            <p class="contact-text ml-5">
                You will be logged out due to inactivity in: 
            </p>
            <p class="contact-text mb-3 ml-5 count-down">
                <vue-countdown :time="time" @end="timeOut" :interval="1000" v-slot="{ minutes, seconds }">
                     {{  minutes }} : {{ seconds }}
                </vue-countdown>
            </p>
            <b-button :disabled="isLoadingRefreshToken" @click="refleshToken" class="btn mt-3 btn-customize">
                <loader v-if="isLoadingRefreshToken" />
                Continue
            </b-button>
        </div>
    </div>     
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import Loader from "@/components/Loader";
import {
  getRefleshToken,
  getAuthenticatedUserByCache
} from "@/helpers";
export default {
  name: 'LoginConfirmModal',
  components:{
      VueCountdown,
      Loader
  },
  data () {
    return {
        isLoadingRefreshToken: false
    }
  },
  methods: {
      refleshToken() {
            let refreshToken = this.authenticatedUser.merchantPanelRefleshToken; 

            this.isLoadingRefreshToken = true 
            
            this.$store
            .dispatch("auth/reAuthentication", { refreshToken})
            .then(data => {
                this.isLoadingRefreshToken = false 
                this.receveidStatusChanges(false)
            })
            .catch(() => {
                this.isLoadingRefreshToken = false
                this.receveidStatusChanges(false)
            });
      },
      receveidStatusChanges(status) {
        this.$emit("EndSessionModalShow", status);
      },
      timeOut(){
          this.receveidStatusChanges(true)
      }
  },
  computed:{
      time(){
          const item = getRefleshToken();
          return item['remainTime']? item['remainTime'] :0;
      },
      authenticatedUser(){
          return getAuthenticatedUserByCache();
      }
  }
}
</script>

<style scoped>
    .bold{
        font-weight: bold;
        font-size: 20px im !important;
    }
    .black-text{
        color: #000000;
        font-size: 21px;
    }

    a.black-text{
        color: #000000;
        font-weight: bold;
        text-decoration: underline;
    }
    .btn-customize{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: #fff !important;
        padding: 5px;
        font-size: 15px;
        width: 35%;
        float: right;
    }

    .btn-customize:active{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: #fff !important;
        padding: 5px;
        font-size: 15px;
        width: 35%;
        float: right;
    }

    .contact-text{
        color: #000000;
        font-family: Roboto;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }
    .count-down{
        font-weight: bold;
        font-size: 19px;
    }
</style>
