<template>
  <div>
    <b-modal
      v-model="show2FAModal"
      @show="reset2FAModal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
    >
      <a href="javascript:void(0);" @click="()=> this.show2FAModal = false"> <img class="close-icon"  src="~@/assets/images/close-modal.svg" alt=""></a>
      <div>
        <div class="row ml-5 mr-5 mb-1 mt-5">
          <div class="col-12">
            <h3 class="mfa-title-text">
                <strong>Security Verification</strong><br>
            </h3>
            <p class="mfa-text">
                Please enter the e-mail verification and Google Authenticator code.
            </p>
            <b-alert v-if="emailVerifyErrorMessage || errorMessage" variant="danger" show dismissible>{{emailVerifyErrorMessage || errorMessage}}</b-alert>
            <b-alert v-if="emailVerifySuccessMessage" variant="success" show dismissible>{{emailVerifySuccessMessage}}</b-alert>
          </div>
        </div>
        <div class="row justify-content-center ml-5 mr-5 mt-1 mb-1">
            <div class="col-7">
              <div row xs12 md12>
                <label>E-mail Verification Code</label>
              </div>
              <v-text-field
                :disabled=isSubmitLoading
                  v-model="emailVerifyCode"
                  @keypress="isNumber($event)"
                  name="input-10-1"
                  autocomplete="off"
                  maxlength = "6"
                  solo single-line
                  :rules="[rules.emailVerifyCode.required]"
              ></v-text-field>
            </div>
            <div class="col-5 align-self-center mb-1">
                <b-button :disabled="isSendLoading || isSubmitLoading" @click="sendEmailVerifyCode();" class="btn btn-sm btn-primary send-code w-100">
                  <loader v-if="isSendLoading" />
                  <span v-else> Request Code</span>
                </b-button>
            </div>
            <div class="col-12">
              <b-form-text id="input-live-help">
                Please enter the 6 digit code received by: {{authenticatedUser.email}}.
              </b-form-text>
            </div>
        </div>
        <div class="row justify-content-center m-5">
            <div class="col-10">
              <div row xs12 md12>
                <label>Google Verification Code</label>
              </div>
              <v-text-field
              :disabled="isSubmitLoading"
                  v-model="twoFACode"
                  @keypress="isNumber($event)"
                  @keyup="isCompleted()"
                  name="input-10-1"
                  autocomplete="off"
                  maxlength = "6"
                  solo single-line
                  :rules="[rules.twoFACode.required]"
              ></v-text-field>
              <b-form-text id="input-live-help">Please enter the 6-digit code Google Authenticator.</b-form-text>
            </div>
            <div class="col-2">
                <img src="~@/assets/images/phone-icon.svg" alt="">
            </div>
            <b-button :disabled="isDisableBtn || isSubmitLoading" @click="fireCallback();" class="btn mt-5 btn-primary w-100">
                <loader v-if="isSubmitLoading" />
                <span v-else> Submit</span>
            </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="showEnable2FAModal"
      hide-header
      hide-footer
      title="Two-Factor Authantication">
        <b>Two-Factor Authantication</b>
        <div class="row ml-3 mr-3 mb-4 mt-4">
          <p class="mfa-title-text" style="font-size:17px">
            Please enable your 2FA to be able to complete your transaction.
          </p>
        </div>
        <div class="row ml-2 mr-2 w-100">
            <b-button variant="primary" class="mr-3" @click="goTo2FAPage()">Enable 2FA </b-button>
            <b-button variant="light" @click="()=> this.showEnable2FAModal = false">Close</b-button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Loader from '../../components/Loader';
import { getAuthenticatedUserByCache } from "@/helpers";
import _ from 'lodash';

export default {

  components: { Loader },

  data () {
    return {
      isSendLoading: false,
      isSubmitLoading: false,

      emailVerifyErrorMessage: null,
      emailVerifySuccessMessage: null,
      errorMessage: null,
      emailVerifyCode: null,
      twoFACode: null,

      rules: {
        twoFACode: {
            required: value => !!value || '2FA Verify code is required',
        },
        emailVerifyCode: {
            required: value => !!value || 'Email verify code is required',
        }
      },
    }
  },

  computed: {
    show2FAModal: {
      get () {
          return this.$store.state.user.show2FAModal
      }, set (value) {
          this.$store.commit('user/setShow2FAModal', value);
      }
    },
    showEnable2FAModal: {
      get () {
          return this.$store.state.user.showEnable2FAModal
      }, set (value) {
          this.$store.commit('user/setShowEnable2FAModal', value);
      }
    },
    authenticatedUser() {
      return getAuthenticatedUserByCache();
    },
    isDisableBtn(){
      let check = this.twoFACode === null || this.twoFACode === '' || this.emailVerifyCode === '' || this.emailVerifyCode === null
      if(this.twoFACode !== null && this.emailVerifyCode !== null && this.twoFACode.length < 6 && this.emailVerifyCode.length < 6) {
        check = true;
      }
      return check;
    }
  },

  methods: {
    reset2FAModal() {
      this.isSendLoading = false;
      this.isSubmitLoading = false;

      this.emailVerifyErrorMessage = null;
      this.emailVerifySuccessMessage = null;
      this.errorMessage = null;
      this.emailVerifyCode = null;
      this.twoFACode = null;
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    goTo2FAPage(){
      this.showEnable2FAModal = false
      this.$router.push('/2fa-authentication');
    },
    sendEmailVerifyCode() {

      let formData = this.$store.getters['user/get2FAFormData'];

      this.isSendLoading = true;

      let postData = {
        address : formData.address,
        destinationTag : formData.destination_tag,
        cryptoCurrency : formData.cryptocurrency,
        coinAmount : formData.cryptoAmount,
        // token : this.payment.token,
        type : 'REFUND',
      };

      this.$store
        .dispatch("merchant/sendWithdrawEmailVerify", postData)
        .then(() => {
          this.isSendLoading = false;
          this.emailVerifyErrorMessage = null;
          this.errorMessage = null;
          this.emailVerifySuccessMessage = "E-mail has been sent successfully";
        })
        .catch(data => {
          this.isSendLoading = false;
          this.emailVerifySuccessMessage = null;
          this.errorMessage = null;
          this.emailVerifyErrorMessage = data.message;
        });

    },
    isCompleted: _.debounce(function() {
        if (this.twoFACode.length==6 && this.emailVerifyCode.length==6) {
            this.fireCallback()
        }
    }, 350),
    fireCallback() {
      let data = {
        emailVerifyCode: this.emailVerifyCode,
        twoFACode: this.twoFACode,
      }

      let callback = this.$store.getters["user/get2FASubmitCallback"];

      callback(data)

      this.show2FAModal = false
      this.isSubmitLoading = false
    }
  }
}
</script>

<style scoped>

</style>
