import { rejectError } from "@/helpers";
import axiosInstance from "../../services";

export default {
  namespaced: true,
  state: {
    customerAddress: {}
  },
  getters: {
    getCustomerAddress(state) {
      return state.customerAddress;
    }
  },
  actions: {
    async createCustomerAddress({ commit }, data) {
      try {
        const res = await axiosInstance.post(
          `/customer/panel/deposit/address`,
          data
        );
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          commit("setCustomerAddress", response.data);
        } else {
          rejectError("there is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    }
  },
  mutations: {
    setCustomerAddress(state, customerAddress) {
      return (state.customerAddress = customerAddress);
    }
  }
};
