import axiosInstance from '@/services'
import {
  rejectError,
  getDomainInfoByCache,
  removeDomainInfoByCache,
  saveAuthenticatedUserToCache,
  removeAuthenticatedUserByCache,
  removeOrderLimit,
  removeBusinessInfo,
  getAPIURLInfo,
  refleshedTokenByAuth
} from "@/helpers";
var jwt = require('jsonwebtoken');
import channel from '../../mixins/SessionControl';

export default {
  namespaced: true,
  state: {
    user: null,
    passwordReset: null,
    isAuthResolved: false,
    currentJWT: localStorage.getItem("merchant-panel-token") || ""
  },
  getters: {
    authUser: (state, getters) =>
      state.currentJWT ? JSON.parse(atob(getters.jwt.split(".")[1])) : null,
    isAuthenticated(state) {
      return !!state.user;
    },
    jwt: state => state.currentJWT,
    getPasswordReset(state) {
      return state.passwordReset;
    }
  },
  actions: {
      loginWithEmailAndPassword({ commit }, userData) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("/merchants/merchantuser/panel/login", userData)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        let authUser = getUserFromToken({ ...response, userVerified: "false" });
                        if (authUser.roles) {
                            authUser.isRefleshToken = "true";
                            saveAuthenticatedUserToCache(authUser);
                            commit("setJWT", response.data.token);
                            resolve(response);
                        }
                    } else {
                        rejectError("there is an unexpected error");
                        reject(response.message);
                    }
                })
                .catch(function(error) {
                  if (error.response.data.code === "304") {
                      window.location = `/new-ip-device`;
                  } else {
                      rejectError(error);
                      reject(error);
                  }
              });
        });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/merchants/merchantuser/panel/logout")
          .then(res => {
            const response = res.data;
            if (response.code === "00" && response.status === "APPROVED") {
              removeAuthenticatedUserByCache();
              removeDomainInfoByCache();
              removeBusinessInfo();
              channel.postMessage("sessionClosed")
              window.location = "/";
              resolve(response);
            } else {
              rejectError("there is an unexpected error");
              reject(response.message);
            }
          })
          .catch(err => {
            commit("setAuthState", null);
            rejectError(err);
            reject(err);
          });
      });
    },
    sendPasswordResetLink({ commit }, userData) {
      const options = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const domainInfo = getDomainInfoByCache();
      const apiUrlInfo = getAPIURLInfo();
      const postData = {
        username: userData.username,
        environment: `${domainInfo ? domainInfo.brand_name : ""}`,
        domain_url: `${apiUrlInfo ? apiUrlInfo.hostName : ""}`
      };
      return axiosInstance
        .post(
          `/merchants/merchantuser/panel/requestPasswordReset`,
          postData,
          options
        )
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            commit("setPasswordResetRequest", response.data);
          } else {
            rejectError(response.message);
          }
        })
        .catch(err => rejectError(err));
    },
    validatePasswordResetLink({ commit }, resetPasswordData) {
      const options = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const postData = {
        token: resetPasswordData.token
      };
      return axiosInstance
        .post(
          `/merchants/merchantuser/panel/validatePasswordReset`,
          postData,
          options
        )
        .then(res => {
          const response = res.data;
          if (
            response.code === "00" &&
            response.status === "APPROVED" &&
            res.data == true
          ) {
            if (process.env.NODE_ENV === "development") {
              window.console.log(res.data);
            }
            return res.data;
          } else {
            return res.data;
          }
        })
        .catch(err => rejectError(err));
    },
    processPasswordReset({ commit }, processPasswordResetData) {
      const options = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const postData = {
        token: processPasswordResetData.token,
        password: processPasswordResetData.password,
        twoFaCode: processPasswordResetData.twoFaCode,
      };
      return axiosInstance
        .post(
          `/merchants/merchantuser/panel/processPasswordReset`,
          postData,
          options
        )
        .then(res => {
          const response = res.data;
          if (
            response.code === "00" &&
            response.status === "APPROVED" &&
            res.data == true
          ) {
            if (process.env.NODE_ENV === "development") {
              window.console.log(res.data);
            }
            return res.data;
          } else {
            return res.data;
          }
        })
        .catch(err => rejectError(err));
      },
      processNewDeviceRegistration({ commit }, processNewDeviceRegistrationData) {

        let token = processNewDeviceRegistrationData.token;
        return axiosInstance
            .post(
                `/merchants/panel/ip-list/validate?token=${token}`
            )
            .then(res => {
                const response = res.data;
                if (response.code === "00" && response.status === "APPROVED") {
                    if (process.env.NODE_ENV === "development") {
                        window.console.log(res.data);
                    }
                    return true;
                }
            })
            .catch(function(error) {
                if (error.response.data.code === "305") {
                    return false;
                } else {
                    rejectError(error);
                }
            });
    },
    validateSelfRegistration({ commit }, data) {
      const token = data.token;
      return axiosInstance
        .post(
          `/self-registration/self-registration-request/validateConfirmationMail/${token}`,
          {}
        )
        .then(res => {
          const response = res.data;
          if (
            response.code === "00" &&
            response.status === "APPROVED" &&
            res.data == true
          ) {
            if (process.env.NODE_ENV === "development") {
              window.console.log(res.data);
            }
            return res.data;
          } else {
            return res.data;
          }
        })
        .catch(err => {
          return rejectError(err);
        });
    },
    reAuthentication({ commit }, creadentialData) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/merchants/user/panel/re-auth", creadentialData)
          .then(res => {
            const response = res.data;
            if (response.code === "00" && response.status === "APPROVED") {
              let authUser = getUserFromToken({ ...response, userVerified: "true" });
              if (authUser.roles) {
                authUser.tokenExpireTime = res.headers["x-token-expire-time"];
                refleshedTokenByAuth(authUser);
                commit("setJWT", response.data.token);
                resolve(response);
              } else {
                rejectError("there is an unexpected error");
                reject();
              }
            } else {
              rejectError("there is an unexpected error");
              reject(response.message);
            }
          })
          .catch(err => {
            rejectError(err);
            reject(err);
          });
      });
    },
    send2FAResetRequest({ commit }, userData) {

      const apiUrlInfo = getAPIURLInfo();
      const postData = {
        email: userData.email,
        domainUrl: `${apiUrlInfo ? apiUrlInfo.hostName : ""}`
      };
      return axiosInstance
        .post(
          `/merchants/merchantuser/panel/request2FAReset`,
          postData
        )
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            return response.data
          } else {
            rejectError(response.message);
          }
        })
        .catch(err => rejectError(err));
    },
  },

  mutations: {
    setAuthUser(state, user) {
      return (state.user = user);
    },
    setAuthState(state, authState) {
      return (state.isAuthResolved = authState);
    },
    setJWT(state, jwt) {
      state.currentJWT = jwt;
    },
    setPasswordResetRequest(state, passwordReset) {
      state.passwordReset = passwordReset;
    }
  }
};

function getUserFromToken(response) {
    const merchantPanelToken = response.data.accessToken;
    const merchantPanelRefleshToken = response.data.refreshToken;
    const usageType = response.data.usageType;
    let authUser =  jwt.decode(merchantPanelToken);
    const user = {
        email: authUser.email,
        userName: authUser.preferred_username,
        firstName: authUser.given_name,
        lastName: authUser.family_name,
        roles: authUser.roles,
        uuid: authUser.sub,
        exp: authUser.exp,
        merchantId: authUser.merchant_id,
        userVerified: response.userVerified,
        merchant2FaEnabled: "true",
        merchant2FaVerified: "true",
    };
    const userWithToken = {...user, merchantPanelToken, merchantPanelRefleshToken, usageType};
    return userWithToken;
}
