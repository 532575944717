import { rejectError } from '@/helpers'
import axiosInstance from '../../services';
import moment from 'moment'

export default {
    namespaced: true,
    state: {
        withdrawals: [],
        detail: {},
        createWithdraw: {},
        cancelWithdraw: {},
        confirmWithdraw: {},
    },
    getters: {
        getWithdrawals(state) {
            if (state.withdrawals) {
                const { count, list } = state.withdrawals;

                if (list) {
                    list.forEach(element => {
                        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY, h:mm:ss a');
                    });
                }
                return { count, list };
            }
            return null;
        },
        createWithdraw(state) {
            if (state.createWithdraw) {
                return state.createWithdraw;
            }
            return null;
        },
        cancelWithdraw(state) {
            if (state.cancelWithdraw) {
                return state.cancelWithdraw;
            }
            return null;
        },
        confirmWithdraw(state) {
            if (state.confirmWithdraw) {
                return state.confirmWithdraw;
            }
            return null;
        },
        getWithdrawDetail(state) {
            let { balanceRequest, bankInformation, country, currency } = state.detail;
            if (balanceRequest && bankInformation && country && currency) {
                return {
                    amount: balanceRequest.amount,
                    currency: currency,
                    country: country.name,
                    information: bankInformation.information,
                    bankName: bankInformation.bankName,
                    accountNumber: bankInformation.accountNumber,
                    bankSwiftCode: bankInformation.bankSwiftCode
                }
            }
            return null;
        },
    },
    actions: {
        getMerchantWithdrawals({ commit }, data) {
            const { page } = data

            return axiosInstance.get(`/balance/balance-request/panel/withdraw/list?page=${page}&limit=20`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setWithdrawals', response.data)
                    }
                    else {
                        rejectError("There is an error")
                    }
                })
                .catch(err => rejectError(err))
        },
        createWithdraw({ commit }, data) {
            return axiosInstance.post(`/balance/balance-request/panel/create-withdrawal-with-confirmation`, data)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCreateWithdraw', response.data)
                    }
                    else {
                        rejectError("There is an error")
                    }
                })
                .catch(err => rejectError(err))
        },
        confirmWithdraw({ commit }, data) {
            const { id } = data;
            return axiosInstance.get(`/balance/balance-request/panel/withdraw/mail/confirmation/${id}`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setConfirmWithdraw', response.data)
                    }
                    else {
                        rejectError("There is an error")
                    }
                })
                .catch(err => rejectError(err))
        },
        cancelWithdraw({ commit }, data) {
            return axiosInstance.post(`/balance/balance-request/panel/update-status`, data)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCancelWithdraw', response.data)
                    }
                    else {
                        rejectError("There is an error")
                    }
                })
                .catch(err => rejectError(err))
        },
        getWithdrawDetail({ commit }, data) {
            const { id } = data

            return axiosInstance.get(`/balance/balance-request/panel/detail/${id}`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setWithdrawDetail', response.data)
                    }
                    else {
                        rejectError("There is an error")
                    }
                })
                .catch(err => rejectError(err))
        },
    },
    mutations: {
        setWithdrawals(state, withdrawals) {
            return state.withdrawals = withdrawals
        },
        setCreateWithdraw(state, createWithdraw) {
            return state.createWithdraw = createWithdraw
        },
        setWithdrawDetail(state, detail) {
            return state.detail = detail
        },
        setCancelWithdraw(state, cancelWithdraw) {
            return state.cancelWithdraw = cancelWithdraw
        },
        setConfirmWithdraw(state, confirmWithdraw) {
            return state.confirmWithdraw = confirmWithdraw
        },
    }
}
