var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", { attrs: { id: "app" } }, [
    _c(
      "div",
      { staticClass: "app-container app-theme-white" },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in", appear: "" } },
          [_c("Header", { attrs: { headerbg: _vm.headercolor } })],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in", appear: "" } },
          [
            _vm.showMenu
              ? _c("Sidebar", { attrs: { sidebarbg: _vm.sidebarcolor } })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "app-main__outer" },
          [
            _c(
              "div",
              { staticClass: "app-main__inner" },
              [_vm._t("default")],
              2
            ),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in", appear: "" } },
              [_c("Footer")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }