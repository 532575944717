import { rejectError } from '@/helpers'
import axiosInstance from '../../services';
import moment from "moment";
import windowHelper from "@/mixins/WindowHelper";
export default {
    namespaced: true,
    state: {
        orderSummary: {},
        ledgers: [],
    },
    getters: {
        orderSummary(state) {
            const { withdraw, payment, deposit } = state.orderSummary;
            const withdrawAmount = withdraw['totalAmount'] || 0.0;
            const paymentAmount = payment['totalAmount'] || 0.0;
            const depositAmount = deposit['totalAmount'] || 0.0;

            return { withdrawAmount, paymentAmount, depositAmount };
        },
        getOrderGraphSummary(state) {
            const { withdraw, payment, deposit } = state.orderSummary;
            const withdrawContent = withdraw['content'];
            const paymentContent = payment['content'];
            const depositContent = deposit['content'];
            return { withdrawContent, paymentContent, depositContent };
        },
        getBalanceLedgers(state) {
            if (state.ledgers) {
                const { content, totalElements, size, totalPages } = state.ledgers;
                if (content) {
                    let filteredContent = [];
                    content.forEach(ledger => {
                        ledger.createdAt = moment(ledger.createdAt).format('DD-MM-YYYY, HH:mm:ss');
                        ledger.orderId = ledger.orderId === 0 || ledger.orderId === -1 ? '-' : ledger.orderId;
                        ledger.fee = ledger.fee === null ? '-' : ledger.fee;
                        if (ledger.type === 'PAYMENT') {
                            ledger.type = 'RECEIVE PAYMENT';
                        } else if (ledger.type === 'DEPOSIT') {
                            ledger.type = 'RECEIVE DEPOSIT';
                        } else if (ledger.type === 'DEPOSIT_BALANCE') {
                            ledger.type = 'DEPOSIT BALANCE';
                        } else if (ledger.type === 'WITHDRAW_F' || ledger.type === 'WITHDRAW_FAILED') {
                            ledger.type = 'WITHDRAW FAILED';
                        } else if (ledger.type === 'WITHDRAW_BALANCE') {
                            ledger.type = 'WITHDRAW BALANCE';
                        } else if (ledger.type === 'SELL_FAILED') {
                            ledger.type = 'SELL FAILED';
                        } else if (ledger.type === 'BUY_FAILED') {
                            ledger.type = 'BUY FAILED';
                        }
                        else if (ledger.type === 'COIN_STORE') {
                            ledger.type = 'COIN STORE';
                        }

                        if (ledger.transactionType === 'CREDIT') {
                            ledger._rowVariant = "success";
                        } else if (ledger.transactionType === 'DEBIT') {
                            ledger._rowVariant = "danger";
                        }

                        filteredContent.push(ledger);
                    });

                    return { filteredContent, totalElements, size, totalPages };
                }
            }
            return {};
        },
    },
    actions: {
        getOrderSummary({ commit }, data) {
            return axiosInstance.post('/orders/panel/stats', data)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setOrderSummary', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        getLedgers({ commit }, data) {
            const { page, size, currencyCode, orderId, startDate, endDate, sortType } = data;

            return axiosInstance.get(`/balance/merchant-balance/panel/ledgers?page=${page}&size=${size}&currencyCode=${currencyCode}&orderId=${orderId}&startDate=${startDate}&endDate=${endDate}&showFee=${true}&sort=createdAt,${sortType}`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setLedgers', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
    },
    mutations: {
        setOrderSummary(state, orderSummary) {
            return state.orderSummary = orderSummary
        },
        setLedgers(state, ledgers) {
            return state.ledgers = ledgers
        }
    }
}

Number.prototype.toLocaleFixed = function(n) {
    return this.toLocaleString(undefined, {
      minimumFractionDigits: n,
      maximumFractionDigits: n
    });
};
