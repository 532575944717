import { rejectError } from '@/helpers';
import axiosInstance from '../../services';
import moment from "moment";

export default {
  namespaced: true,
  state: {
    earnPanelList: {},
    providerAsset: [],
  },
  getters: {
    getEarnPanelList(state) {
      return state.earnPanelList
    },
    getProviderAsset(state) {
      return state.providerAsset;
    },
  },
  actions: {
    async getAllocationBar({commit}) {
      try {
        const res = await axiosInstance.get('earn/panel/allocation-bar');
        return res.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getActivitiesList({ commit }, data) {
      const { page, filters, limit } = data;
      const {
        asset,
        type,
        status,
        startTime,
        endTime,
      } = filters;

      if (startTime != null) {
        var startTimeFormat = startTime ? moment(startTime).format("YYYY-MM-DDTHH:mm:ss") : null;
      }

      if (endTime != null) {
        var endTimeFormat = endTime ? moment(endTime).format("YYYY-MM-DDTHH:mm:ss") : null;
      }
      
      const postData = {
        asset,
        type,
        status,
        startTime: startTimeFormat,
        endTime: endTimeFormat,
      };

      try {
        const res = await axiosInstance.post(`/earn/panel/pool-request/query?page=${page}&size=${limit}`, postData);
        return res.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getAssetLimit({}, data) {
      const { coinType } = data;
      try {
        const res = await axiosInstance.get(`/earn/asset/code/${coinType}`);
        return res.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    getProviderAsset({ commit }) {
      return axiosInstance
        .get(`/earn/provider-asset`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setProviderAsset", response.data);
          } else {
            rejectError(response.message);
          }
        })
        .catch(err => rejectError(err));
    },
    async getEarnPanelList({ commit }) {
      try {
        const res = await axiosInstance.get(`/earn/panel/list`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setEarnPanelList", response.data);
        } else {
          rejectError("there is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async requestEmailVerify({ commit }, data) {
      const { poolId, coinAmount } = data;
      const postData = {
        poolId: poolId,
        coinAmount: coinAmount
      }
      try {
        await axiosInstance.post(`/earn/panel/send/email-verify`, postData);
      } catch (err) {
        return rejectError(err);
      }
    },
    async earnRedemption({ commit }, data) {
      const { pool, amount } = data;
      const postData = {
        pool: { id:pool },
        amount: amount
      }
      try {
        const res = await axiosInstance.post(`/earn/panel/redemption`, postData);
        return res;
      } catch (err) {
        return rejectError(err);
      }
    },
    async earnPanelStart({}, data) {
      const { investor, pool, amount, earnType, verificationCode, twoFaCode } = data;

      const postData = {
        investor: {id:investor},
        pool: {id:pool},
        amount,
        earnType,
        verificationCode: verificationCode,
        twoFaCode: twoFaCode
      }

      try {
        const res = await axiosInstance.post(`/earn/panel/start`, postData);
        return res;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getInvestor({}) {
      try {
        const res = await axiosInstance.get(`/earn/investor/info`);
        return res.data.data;
      } catch (err) {
        return rejectError(err);
      }
    }
  },
  mutations: {
    setEarnPanelList(state, earnPanelList) {
      return (state.earnPanelList = earnPanelList)
    },
    setProviderAsset(state, providerAsset) {
      return (state.providerAsset = providerAsset);
    },
  }
}