var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "justify-content-center mt-2 ml-4 mr-4 mb-2" },
      [
        _c("h2", { staticClass: "black-text bold mb-4" }, [
          _vm._v("Session Timeout"),
        ]),
        _c(
          "h3",
          { staticClass: "black-text mb-4" },
          [
            _c(
              "v-icon",
              {
                staticStyle: {
                  cursor: "pointer",
                  color: "#000000",
                  "font-size": "21px",
                },
              },
              [_vm._v("info")]
            ),
            _vm._v(" Warning"),
          ],
          1
        ),
        _c("p", { staticClass: "contact-text ml-5 mb-5" }, [
          _vm._v(" You login session has timed out. Please login again. "),
        ]),
        _c(
          "b-button",
          { staticClass: "btn mt-3 btn-customize", on: { click: _vm.logout } },
          [_vm._v("Go to login page")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }