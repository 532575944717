import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import dashboard from './modules/dashboard'
import merchant from './modules/merchant'
import withdrawal from './modules/withdrawal'
import order from './modules/order'
import content from './modules/content'
import user from './modules/user'
import support from './modules/support'
import balance from './modules/balance'
import earn from './modules/earn'
import api from './modules/api'
import customerAddress from "./modules/customerAddress";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    content,
    dashboard,
    merchant,
    withdrawal,
    order,
    user,
    support,
    balance,
    earn,
    api,
    customerAddress
  },
  mutations: {
    setItems(state, { resource, items }) {
      state[resource].items = items;
    },
    setItem(state, { resource, item }) {
      state[resource].item = item;
    },
    addItemToArray(state, { item, index, resource }) {
      Vue.set(state[resource].items, index, item);
    }
  }
});