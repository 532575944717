<template>
  <div class="d-flex">
    <div class="header-btn-lg pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <b-dropdown
              toggle-class="p-0 mr-2"
              menu-class="dropdown-menu-lg"
              variant="link"
              no-caret
              right
            >
              <span slot="button-content">
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img
                    width="42"
                    class="rounded-circle"
                    src="@/assets/images/avatars/profile.png"
                    alt
                  />
                </div>
              </span>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img
                            width="42"
                            class="rounded-circle"
                            src="@/assets/images/avatars/profile.png"
                            alt
                          />
                        </div>
                        <div class="widget-content-left" v-if="authenticatedUser">
                          <div class="widget-heading">{{ authenticatedUser.firstName}} {{authenticatedUser.lastName}} </div>
                          <div class="widget-heading">{{merchantDetail.name}} </div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <button
                            @click="logout"
                            v-if="authenticatedUser"
                            class="btn-pill btn-shadow btn-shine btn btn-focus"
                          >Logout</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-dropdown>
          </div>
          <div class="widget-content-left header-user-info">
            <div class="widget-heading" v-if="authenticatedUser">{{authenticatedUser.firstName}} {{authenticatedUser.lastName}}</div>
            <div class="widget-heading" v-if="authenticatedUser">{{merchantDetail.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { getAuthenticatedUserByCache } from "@/helpers";
import {
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH
);

import channel from "../../../mixins/SessionControl";

export default {
  data: () => ({
    fill1: { gradient: ["#00b09b", "#96c93d"] },
    fill2: { gradient: ["#ff0844", "#ffb199"] },
    fill3: { gradient: ["#f6d365", "#fda085"] },
    showDrawerSection: false,
    isLoading: false
  }),
  components:{
  },
  methods: {
        loadMerchantSummary() {
            this.isLoading = true;
            this.$store
                .dispatch("merchant/getMerchantInfo", {})
                .then(() => {
                  this.$store.dispatch('merchant/getMerchantAppForm');
                  this.isLoading = false;
                })
                .catch(() => {
                  this.isLoading = false;
                });
        },
        logout() {
                this.$store
                .dispatch("auth/logout")
                .then(data => {
                    this.$router.push('/auth/login');
                })
                .catch(() => {});
        }

  },
  created() {
    this.loadMerchantSummary();
  },
  computed: {
    merchantDetail() {
      return this.$store.getters["merchant/getMerchantInfo"];
    },
    authenticatedUser(){
      return getAuthenticatedUserByCache();
    }
  }
};
</script>

<style lang="stylus" scoped>
.bg-info {
  background-color: #00AD5A !important;
}

.btn-focus {
  background-color: white;
  color : black;
  border: 0px !important;
}

.btn-focus.btn-shadow {
    -webkit-box-shadow: 0 0.125rem 0.625rem #69aa8a, 0 0.0625rem 0.125rem #69aa8a;
    box-shadow: 0 0.125rem 0.625rem #69aa8a, 0 0.0625rem 0.125rem #69aa8a;
}
</style>
