import { rejectError } from '@/helpers';
import axiosInstance from '../../services';

export default {

    namespaced: true,
    
    state: {
        balanceList: [],
    },

    getters: {
        getBalanceList(state) {
            if (state.balanceList){
                return state.balanceList;
            }
        }
    },

    actions:{
        async balanceList({ commit }, data) {
            return axiosInstance.get(`/balance/merchant-balance/panel/crypto_currency/all`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setBalanceList', response.data)
                        return response.data;
                    }
                    else {
                        rejectError("there is an unexpected error, getBalanceList")
                    }
                })
                .catch(err => rejectError(err))
        },
        assetAllocationTrade({}) {
            return axiosInstance.get('/balance/merchant-balance/panel/crypto_currency/all')
                .then(res => {
                    const response = res.data;
                    return response;
                })
                .catch(err => rejectError(err))
        },
    },

    mutations:{
        setBalanceList(state, balanceList) {
            return state.balanceList = balanceList;
        },
    }
}