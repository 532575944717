const channel = new BroadcastChannel('sessionChannel')
import router from "../router"

channel.onmessage = function(event) {
    
    if (event.data === 'sessionOpened') {
        router.push("/")
    }

    else if (event.data === 'sessionClosed') {
        router.push("/auth/login")
    }
}

export default channel