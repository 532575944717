var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "header-btn-lg pr-0" }, [
      _c("div", { staticClass: "widget-content p-0" }, [
        _c("div", { staticClass: "widget-content-wrapper" }, [
          _c(
            "div",
            { staticClass: "widget-content-left" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    "toggle-class": "p-0 mr-2",
                    "menu-class": "dropdown-menu-lg",
                    variant: "link",
                    "no-caret": "",
                    right: "",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "icon-wrapper icon-wrapper-alt rounded-circle",
                        },
                        [
                          _c("img", {
                            staticClass: "rounded-circle",
                            attrs: {
                              width: "42",
                              src: require("@/assets/images/avatars/profile.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "dropdown-menu-header" }, [
                    _c(
                      "div",
                      { staticClass: "dropdown-menu-header-inner bg-info" },
                      [
                        _c("div", {
                          staticClass: "menu-header-image opacity-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "menu-header-content text-left" },
                          [
                            _c("div", { staticClass: "widget-content p-0" }, [
                              _c(
                                "div",
                                { staticClass: "widget-content-wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "widget-content-left mr-3" },
                                    [
                                      _c("img", {
                                        staticClass: "rounded-circle",
                                        attrs: {
                                          width: "42",
                                          src: require("@/assets/images/avatars/profile.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.authenticatedUser
                                    ? _c(
                                        "div",
                                        { staticClass: "widget-content-left" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "widget-heading" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.authenticatedUser
                                                    .firstName
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.authenticatedUser
                                                      .lastName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "widget-heading" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.merchantDetail.name
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget-content-right mr-2",
                                    },
                                    [
                                      _vm.authenticatedUser
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-pill btn-shadow btn-shine btn btn-focus",
                                              on: { click: _vm.logout },
                                            },
                                            [_vm._v("Logout")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "widget-content-left header-user-info" }, [
            _vm.authenticatedUser
              ? _c("div", { staticClass: "widget-heading" }, [
                  _vm._v(
                    _vm._s(_vm.authenticatedUser.firstName) +
                      " " +
                      _vm._s(_vm.authenticatedUser.lastName)
                  ),
                ])
              : _vm._e(),
            _vm.authenticatedUser
              ? _c("div", { staticClass: "widget-heading" }, [
                  _vm._v(_vm._s(_vm.merchantDetail.name)),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }