var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-header header-shadow", class: _vm.headerbg },
    [
      _c("a", { staticClass: "logo-src", attrs: { href: _vm.redirectUrl } }, [
        _c("img", {
          staticClass: "logo-default",
          attrs: { src: _vm.logoUrl, height: "25px", alt: "" },
        }),
      ]),
      _c("div", { staticClass: "app-header__content" }, [
        _c("div", { staticClass: "app-header-left" }, [_c("HeaderBalance")], 1),
        _c("div", { staticClass: "app-header-right" }, [_c("UserArea")], 1),
      ]),
      _c("div", { staticClass: "app-header__mobile-menu" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "hamburger close-sidebar-btn hamburger--elastic",
              class: { "is-active": _vm.isOpen },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.toggleMobile("closed-sidebar-open")
                },
              },
            },
            [_vm._m(0)]
          ),
        ]),
      ]),
      _c("div", { staticClass: "app-header__menu" }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }