import { rejectError } from '@/helpers'
import axiosInstance from '../../services/index'

export default {
    namespaced: true,
    state: {
        apiInfo: {},
        ipList:[],
        callbackUrl: {},
        merchantInfo:{},
        demoRequest:{},
        checkTransaction:{}, 
    },
    getters: {
        getApiInfo(state) {
            return state.apiInfo;
        },
        getIpList(state) {
            return state.ipList;
        },
        getCallbackUrl (state) {
            return state.callbackUrl
        },
        getMerchantInfo(state) {
            return state.merchantInfo;
        },
        getDemoRequest(state) {
            return state.demoRequest;
        },
        getCheckTransaction(state) {
            return state.checkTransaction;
        },
    },
    actions: {
        createApiInfo({ commit }, data) {
            const twoFACode = data.twoFACode;
            return axiosInstance
              .post(`/merchants/panel/api/generate`, { twoFACode })
              .then(res => {
                const response = res.data;
                if (response.code === "00" && response.status === "APPROVED") {
                  commit("setApiInfo", response.data);
                } else {
                  rejectError("there is an unexpected error");
                }
              })
              .catch(err => rejectError(err));
        },
        createIpRestriction({ commit }, data) {
            let ipList = data.ipList;
            return axiosInstance.post(`/merchants/panel/api/whitelist-ip`,{ ipList })
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setIpList', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        createCallbackUrl({ commit }, data) {
            return axiosInstance.post(`/merchants/panel/merchant/api/callback-url`, data)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCallbackUrl', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        getIpList({ commit }) {
            return axiosInstance.get(`/merchants/panel/api/whitelist-ip`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setIpList', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        getCallbackUrl({ commit }) {
            return axiosInstance.get(`/merchants/panel/merchant/api/callback-url`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCallbackUrl', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        async getMerchantInfo({ commit }) {
            return await axiosInstance.get(`/merchants/panel/merchant/businessInfo`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setMerchantInfo', response.data)
                        return response.data
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        sendRequestDemo({ commit }, data) {
            let email = data.email;
            return axiosInstance.post(`/merchants/panel/merchant/demoRequest`, {email})
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setDemoRequest', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        async checkMerchantTransactions({ commit }) {
            try {
                const res = await axiosInstance.post(`/merchants/panel/merchant/demo-request/control`, {});
                const response = res.data;
                if (response.code === "00" && response.status === "APPROVED") {
                    commit('setCheckTransaction', response.data);
                }
                else {
                    rejectError("there is an unexpected error");
                }
            } catch (err) {
                return rejectError(err);
            }
        },
    },
    mutations: {
        setApiInfo(state, apiInfo) {
            return state.apiInfo = apiInfo
        },
        setIpList(state, data) {
            return state.ipList = data.ipList
        },
        setCallbackUrl (state, data){
            return state.callbackUrl = data;
        },
        setMerchantInfo(state, data) {
            return state.merchantInfo = data;
        },
        setDemoRequest(state, data) {
            return state.demoRequest = data;
        },
        setCheckTransaction(state, data) {
            return state.checkTransaction = data;
        }
    }
}
