var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-esc": true,
            "no-close-on-backdrop": true,
            "hide-header": "",
            "hide-footer": "",
          },
          on: { show: _vm.reset2FAModal },
          model: {
            value: _vm.show2FAModal,
            callback: function ($$v) {
              _vm.show2FAModal = $$v
            },
            expression: "show2FAModal",
          },
        },
        [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0);" },
              on: { click: () => (this.show2FAModal = false) },
            },
            [
              _c("img", {
                staticClass: "close-icon",
                attrs: {
                  src: require("@/assets/images/close-modal.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _c("div", [
            _c("div", { staticClass: "row ml-5 mr-5 mb-1 mt-5" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("h3", { staticClass: "mfa-title-text" }, [
                    _c("strong", [_vm._v("Security Verification")]),
                    _c("br"),
                  ]),
                  _c("p", { staticClass: "mfa-text" }, [
                    _vm._v(
                      " Please enter the e-mail verification and Google Authenticator code. "
                    ),
                  ]),
                  _vm.emailVerifyErrorMessage || _vm.errorMessage
                    ? _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "danger",
                            show: "",
                            dismissible: "",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.emailVerifyErrorMessage || _vm.errorMessage
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.emailVerifySuccessMessage
                    ? _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "success",
                            show: "",
                            dismissible: "",
                          },
                        },
                        [_vm._v(_vm._s(_vm.emailVerifySuccessMessage))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "row justify-content-center ml-5 mr-5 mt-1 mb-1" },
              [
                _c(
                  "div",
                  { staticClass: "col-7" },
                  [
                    _c("div", { attrs: { row: "", xs12: "", md12: "" } }, [
                      _c("label", [_vm._v("E-mail Verification Code")]),
                    ]),
                    _c("v-text-field", {
                      attrs: {
                        disabled: _vm.isSubmitLoading,
                        name: "input-10-1",
                        autocomplete: "off",
                        maxlength: "6",
                        solo: "",
                        "single-line": "",
                        rules: [_vm.rules.emailVerifyCode.required],
                      },
                      on: {
                        keypress: function ($event) {
                          return _vm.isNumber($event)
                        },
                      },
                      model: {
                        value: _vm.emailVerifyCode,
                        callback: function ($$v) {
                          _vm.emailVerifyCode = $$v
                        },
                        expression: "emailVerifyCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-5 align-self-center mb-1" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn btn-sm btn-primary send-code w-100",
                        attrs: {
                          disabled: _vm.isSendLoading || _vm.isSubmitLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sendEmailVerifyCode()
                          },
                        },
                      },
                      [
                        _vm.isSendLoading
                          ? _c("loader")
                          : _c("span", [_vm._v(" Request Code")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("b-form-text", { attrs: { id: "input-live-help" } }, [
                      _vm._v(
                        " Please enter the 6 digit code received by: " +
                          _vm._s(_vm.authenticatedUser.email) +
                          ". "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "row justify-content-center m-5" },
              [
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c("div", { attrs: { row: "", xs12: "", md12: "" } }, [
                      _c("label", [_vm._v("Google Verification Code")]),
                    ]),
                    _c("v-text-field", {
                      attrs: {
                        disabled: _vm.isSubmitLoading,
                        name: "input-10-1",
                        autocomplete: "off",
                        maxlength: "6",
                        solo: "",
                        "single-line": "",
                        rules: [_vm.rules.twoFACode.required],
                      },
                      on: {
                        keypress: function ($event) {
                          return _vm.isNumber($event)
                        },
                        keyup: function ($event) {
                          return _vm.isCompleted()
                        },
                      },
                      model: {
                        value: _vm.twoFACode,
                        callback: function ($$v) {
                          _vm.twoFACode = $$v
                        },
                        expression: "twoFACode",
                      },
                    }),
                    _c("b-form-text", { attrs: { id: "input-live-help" } }, [
                      _vm._v(
                        "Please enter the 6-digit code Google Authenticator."
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-2" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/phone-icon.svg"),
                      alt: "",
                    },
                  }),
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "btn mt-5 btn-primary w-100",
                    attrs: {
                      disabled: _vm.isDisableBtn || _vm.isSubmitLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.fireCallback()
                      },
                    },
                  },
                  [
                    _vm.isSubmitLoading
                      ? _c("loader")
                      : _c("span", [_vm._v(" Submit")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            title: "Two-Factor Authantication",
          },
          model: {
            value: _vm.showEnable2FAModal,
            callback: function ($$v) {
              _vm.showEnable2FAModal = $$v
            },
            expression: "showEnable2FAModal",
          },
        },
        [
          _c("b", [_vm._v("Two-Factor Authantication")]),
          _c("div", { staticClass: "row ml-3 mr-3 mb-4 mt-4" }, [
            _c(
              "p",
              {
                staticClass: "mfa-title-text",
                staticStyle: { "font-size": "17px" },
              },
              [
                _vm._v(
                  " Please enable your 2FA to be able to complete your transaction. "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "row ml-2 mr-2 w-100" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goTo2FAPage()
                    },
                  },
                },
                [_vm._v("Enable 2FA ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "light" },
                  on: { click: () => (this.showEnable2FAModal = false) },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }