import jwt from 'jsonwebtoken';
export default (to, from, next) => {
  const onlyAuthUser = to.matched.some((record) => record.meta.onlyAuthUser);
  if (onlyAuthUser) {

    // Token and expired time check
    let authenticatedUser = JSON.parse( localStorage.getItem("authenticatedUser"));
    let token = authenticatedUser.merchantPanelToken;
    let checkToken = checkTokenValidity(token);
    if (!checkToken) {
      return next("/auth/login");
    }
    
    // UsageType check
    if (authenticatedUser.usageType === 'COMMON') {
      return next();
    } else if (authenticatedUser.usageType === 'TWO_FA_VERIFY') {
      return next("/auth/2fa-verify");
    } else if (authenticatedUser.usageType === 'TWO_FA_CREATE') {
      return next("/auth/2fa-enable");
    } else {
      return next("/auth/login");
    }

  }
};

function checkTokenValidity(token) {
  if (token) {
      const decodedToken = jwt.decode(token)
      return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
  }
  return false
}
