<template>
  <v-footer height="auto" color="default">
    <v-layout justify-center wrap>
      <div pa-3 text-xs-center gray--text xs12>
        &copy; Copyright {{ new Date().getFullYear() }}.<strong> {{appCompanyName}}. All rights reserved.</strong>
      </div>
    </v-layout>
  </v-footer>
</template>

<script>
  import { getDomainInfoByCache} from "@/helpers";
  export default {
    data: () => ({
      appCompanyName: `${ getDomainInfoByCache() ? getDomainInfoByCache().company_name : ''}`
    })
  }
</script>
