<template>
    <div class="headerLeftContent">
      <div class="balanceInfoWrap">
        <div class="headlineText">Merchant Balance</div>
        <div class="balanceInfo">
          <loader v-if="isLoading" />
          <div v-else class="totalBalance">
            <div class="balanceText mr-3">
              <div>{{ amountFormatter('fiat', merchantTotalBalance,balanceCurrencyCode) }}</div>
              <div v-b-tooltip.hover title="This is your approx. FIAT + Cryptocurrency balance at the moment.">
                <v-icon>info</v-icon>
              </div>
            </div>
            <div class="position-relative mr-4">
              <div class="selectedBalance">
               <span class="mr-2"> {{ balanceCurrencyCode }}</span>
                <i class="fa-regular fa-chevron-down"></i>
                <ul class="balanceList">
                  <template v-for="(item, index) in getBalanceList">
                    <li v-if="item !== balanceCurrencyCode" :key="index">
                      <a @click.prevent="changeBalanceType($event)">{{ item }}</a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="env !== 'prod'" class="sandboxInfo">
        <b-alert class="my-2"  variant="warning" show>
          This is a <b>Sandbox</b> environment, used for testing {{appProductName}} services.
        </b-alert>
      </div>
      <div class="accountActivateBtn">
        <b-button v-if="authenticatedUser.userVerified !== 'true'"
          :disabled="isActivateButtonDisabled"
          :href="appformUrl + merchantDetail.appformToken"
          target="_blank" class="btn btn-sm btn-success">
          Activate Your Account
        </b-button>
      </div>
    </div>
</template>

<script>
import Loader from "../../../components/Loader";
import {getAuthenticatedUserByCache, getDomainInfoByCache} from "@/helpers";

export default {
  components: {
    Loader
  },
  data: () => ({
    firstShowBalance: false,
    isLoading: false,
    domainInfo: getDomainInfoByCache(),
    env: process.env.NODE_ENV.toLowerCase(),
    authenticatedUser: {},
    appformUrl: `${process.env.VUE_APP_APPFORM_URL}?token=`,
    balanceTypeList: [],
    balanceCurrencyCode: '',
    merchantTotalBalance: ''
  }),
  methods: {
    changeBalanceType(event) {
      if(this.balanceCurrencyCode !== event.target.text){
        this.balanceCurrencyCode = event.target.text;
        this.getMerchantHeaderBalances();
        localStorage.setItem('balanceSelectedCurrency', this.balanceCurrencyCode);
      }
    },
    getMerchantHeaderBalances() {
      this.isLoading = true;
      this.$store
        .dispatch("merchant/getMerchantHeaderBalanceWithCurrency", this.balanceCurrencyCode)
        .then((response) => {
          let merchantTotalBalance = response.data;
          this.balanceCurrencyCode = response.data.total.currency
          this.merchantTotalBalance = merchantTotalBalance.total.balance.toFixed(2);
          this.$store.commit('merchant/setTotalBalanceBySelectedFiat', response.data.total)
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    checkBalance() {
      setInterval(() => {
        this.getMerchantHeaderBalances();
      }, 300000);
    },
    async getSettlementFiatList() {
      try {
        const response = await this.$store.dispatch("merchant/getSettlementFiatCurrencies")
        if(response.data.code === "00") {
          return this.balanceTypeList = response.data.data
        }
      }catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    getBalanceList() {
      return [this.balanceCurrencyCode, ...this.balanceTypeList.filter(currency => currency !== this.balanceCurrencyCode)]
    },
    appProductName(){
      return this.domainInfo ? this.domainInfo.brand_name : false;
    },
    merchantDetail() {
      return this.$store.getters["merchant/getMerchantInfo"];
    },
    async isActivateButtonDisabled() {
      return await this.$store.getters["merchant/isActivateButtonDisabled"];
    },
  },
  async created() {
    this.authenticatedUser = getAuthenticatedUserByCache();
    await this.getSettlementFiatList()
    if(localStorage.getItem('balanceSelectedCurrency')){

      let isHave = this.balanceTypeList.find(item => item === localStorage.getItem('balanceSelectedCurrency'))
      if(isHave) {
        this.balanceCurrencyCode = localStorage.getItem('balanceSelectedCurrency');
      } else {
        this.balanceCurrencyCode = this.balanceTypeList[0]

        localStorage.setItem('balanceSelectedCurrency', this.balanceCurrencyCode)
      }
    } else {

      this.balanceCurrencyCode = this.balanceTypeList[0]

      localStorage.setItem('balanceSelectedCurrency', this.balanceCurrencyCode)
    }

    if (!this.firstShowBalance) {
      this.firstShowBalance = true;
      this.getMerchantHeaderBalances();
    } else {
      setInterval(() => {
        this.getMerchantHeaderBalances();
      }, 300000);
    }
  }
};

Number.prototype.toLocaleFixed = function(n) {
    return this.toLocaleString(undefined, {
      minimumFractionDigits: n,
      maximumFractionDigits: n
    });
};

</script>

<style lang="scss" scoped>
  .headerLeftContent{display:flex; width:100%; align-items:center; padding-left:10px;
    .balanceInfoWrap{display:flex; flex-direction: column; padding-top: 4px;
      .headlineText{display: flex; align-items: center; color: #111; font-size: 14px; min-width: 150px; height: 12px; font-weight: 500;}
      .balanceInfo{display: flex; align-items: center; height: 25px;
        .totalBalance{display: flex; align-items: center; width: 100%; height: 30px; min-width:205px; justify-content:space-between;
          .balanceText{display:flex;
            >div{
              &:nth-child(1){color:#00AD5A; font-size:18px;}
              &:nth-child(2){width:30px; display:flex; align-items:center; justify-content:center; cursor:pointer;
                i{font-size:18px;}
              }
            }
          }
          
          .balanceType{
            width: 80px; position: relative; height: 30px; display: flex; margin-top:-19px; margin-left: 10px;

            ul{width:100%; display:flex; flex-wrap:wrap; position:absolute; top:0; left:0; background-color:#fff; width:65px; padding:0; margin:0; box-shadow:1px 1px 5px #ddd; max-height:35px; overflow:hidden; transition:max-height 0.2s;
              li{display:flex; width:100%;
                a{display:flex; height:35px; width:100%; font-size:16px; padding:5px 10px; color:#111!important; position:relative; border-bottom: 1px solid #f1f1f1;
                  &:hover{text-decoration:none; background-color:#f1f1f1;}
                }
                &:nth-last-child(1){
                  a{border-bottom:0;}
                }
                &:first-child{
                  a{
                    &:after{font: var(--fa-font-solid); content: "\f107"; position: absolute; right: 5px; top: 8px;}
                  }
                }
              }
              &:hover{max-height:305px; overflow-y: auto;
                overflow-y: overlay; scrollbar-gutter: stable; }
            }
          }
        }
      }
    }
    .sandboxInfo{display:flex; margin-right:20px;
      .alert{height:45px;}
    }
  }
  .selectedBalance{
             height: 30px; display: flex; font-weight: 500; align-items: center; justify-content: center; font-size: 16px; cursor: pointer; background-color: #fff; box-shadow:1px 1px 5px #ddd; padding: 18px 10px; margin-bottom: 20px;
            &:hover{
              background-color:#f1f1f1;
              &>.balanceList {
                max-height: 255px;
                overflow-y: auto;
                transition: max-height 0.2s;
              }
            }
          }
          .balanceList {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #fff;
            width: 100%;
            padding: 0;
            margin-top: -20px;
            box-shadow:1px 1px 5px #ddd;
            max-height: 0;
            overflow-y: auto;
            transition: max-height 0.2s;
            li {
              list-style: none;
              a {
                cursor: pointer;
                display: block;
                padding: 5px 10px;
                font-size: 15px;
              }
              &:not(:last-child) {
                a {
                    border-bottom: 1px solid #f1f1f1;
                  }
              }
            }
          }
</style>
