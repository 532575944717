import {walletAddressRegexes} from "@/helpers";
export default {
  data() {
    return {
      alphanumericRegex: /^[A-Za-z0-9]+$/,
      regexWithSpace: /^[A-Za-z0-9 ]+$/
    }
  },
  computed: {
    allCryptoCurrencies() {
      return this.$store.getters['content/getAllCryptoCurrencies']
    }
  },
  methods: {
    normalizeObject (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    getCurrencyIcon(code) {
      const isCrypto = this.allCryptoCurrencies.find(item => item.code === code)
      if (isCrypto) {
        return   `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/coin/${code}.svg`
      }
      return `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/currency/${code}.svg`
    },
    isEmpty (obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false
        }
      }
      return true
    },
    firstLetterUppercase (text) {
      return text.charAt(0).toUpperCase() + text.substring(1, text.length);
    },
    modifyCardString (string) {
      return string.slice(0, 4) + ' ' + string.slice(4, 6) + '** **** ' + string.slice(7)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    scrollToItem (item) {
      document.querySelector(item).scrollIntoView(true)
      window.scrollBy(0, -40)
    },
    showModal (id) {
      let modalBackdrop = document.createElement('div')
      modalBackdrop.classList.add('modal-backdrop')
      modalBackdrop.classList.add('show')
      document.body.append(modalBackdrop)
      document.querySelector(id).style.display = 'block'
      document.querySelector('body').classList.add('modal-open')
    },
    closeModal (id) {
      document.querySelector(id).style.display = 'none'
      document.querySelector('.modal-backdrop').remove()
      document.querySelector('body').classList.remove('modal-open')
    },
    addWindowEvent (eventName, callback) {
      window.addEventListener(eventName, callback)
    },
    removeWindowEvent (eventName, callback) {
      window.removeEventListener(eventName, callback)
    },
    addBeforeUnloadEvent () {
      this.addWindowEvent('beforeunload', (event) => {
        event.returnValue = 'You have unfinished changes!'
      })
    },
    removeBeforeUnloadEvent () {
      this.removeWindowEvent('beforeunload', (event) => { })
    },
    addHotJarTag (name, value) {
      if (window !== undefined && window.hj !== undefined) {
        window.hj('tagRecording', [name, value])
      }
    },
    amountFormatter(type = null, amount, currency = null){
      if(!amount || amount === '-'){
        return amount
      }
      if(currency) {
        let isCrypto = this.allCryptoCurrencies.find(item => item.code === currency )
        if(isCrypto) {
          return new Intl.NumberFormat('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 6, useGrouping: true }).format(amount)
        }
        return new Intl.NumberFormat('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 2, useGrouping: true }).format(amount)

      }
      if(type === 'fiat') {
        return new Intl.NumberFormat('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 2, useGrouping: true }).format(amount)
      }
      return new Intl.NumberFormat('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 6, useGrouping: true }).format(amount)
    },
    checkWalletAddressRegex(code, address) {
      return walletAddressRegexes[code].test(address)
    },
    isAlphanumericRegex(event, space) {
      let code = event.code;
      if(code == 'ArrowLeft' || code == "ArrowRight" || code == "Backspace" || code == "Delete") {
        return;
      }
      
      let key = event.keyCode;
      let str = String.fromCharCode(key);
      
      if(space) {

        if(code == "Space") {
          return true
        }

        if(event.clipboardData != undefined) {
          let pastedData = event.clipboardData.getData("text");
          if(this.regexWithSpace.test(pastedData)) {
            return true;
          } else {
            return false;
          }
        }

        if(this.regexWithSpace.test(str)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }

      } else {

        if(event.clipboardData != undefined) {
          let pastedData = event.clipboardData.getData("text");
          if(this.alphanumericRegex.test(pastedData)) {
            return true;
          } else {
            return false;
          }
        }
        
        if(!this.alphanumericRegex.test(str)) {
          event.preventDefault();
          return false;
        }
      }
    },
  }, 
}
