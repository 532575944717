<template>
    <v-app id="app">
        <div class="app-container app-theme-white">
            <transition name="fade" mode="out-in" appear>
                <Header :headerbg="headercolor"/>
            </transition>
            <transition name="fade" mode="out-in" appear>
                <Sidebar v-if="showMenu" :sidebarbg="sidebarcolor"/>
            </transition>
            <div class="app-main__outer">
                <div class="app-main__inner">
                    <slot></slot>
                </div>
                <transition name="fade" mode="out-in" appear>
                    <Footer/>
                </transition>
            </div>
        </div>
    </v-app>
</template>

<script>
    import Header from "../components/Header";
    import Sidebar from "../components/Sidebar";
    import Footer from "../components/Footer";

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faCog,
    } from '@fortawesome/free-solid-svg-icons'

    library.add(
        faCog,
    );
    export default {
        name: 'app',
        components: {
            Header,
            Sidebar,
            Footer,
        },
        data() {
            return {
                headercolor: null,
                sidebarcolor: null,
                tokenCheckStatus: false,
                showMenu: false
            }
        },
        created() {
            this.tokenCheckStatus = this.tokenCheck();
            this.getMerchantInfo();
        },
        methods: {
            tokenCheck() {
                const authenticatedUser = localStorage.getItem('authenticatedUser');
                return authenticatedUser != null && authenticatedUser.merchantPanelToken;
            },
            getMerchantInfo(){
                this.$store
                    .dispatch("api/getMerchantInfo")
                    .then(() => {
                    this.merchantInfo = this.$store.getters['api/getMerchantInfo'];
                    localStorage.setItem('dismissOnboarding', this.merchantInfo.verified)
                    this.showMenu = true;
                    })
                    .catch(() => {});
                },
             }
    }
</script>
