// numberOnlyMixin.js
const numberOnlyDirective = {
    bind(el, binding) {
      // Add an event listener to the input element to handle user input
      el.addEventListener('input', event => {
        const inputValue = event.target.value;
  
        // Replace commas with dots for consistent number format
        const cleanedValue = inputValue.replace(/,/, '.');
  
        // Accept only numbers and optionally one dot
        const regex = /^-?\d*(\.\d*)?$/;
        if (!regex.test(cleanedValue)) {
          // If the input doesn't match the allowed format, clear the value
          event.target.value = '';
        } else {
          // Update the input value with the cleaned value
          event.target.value = cleanedValue;
        }
      });
    }
  };
  
  const numberOnlyMixin = {
    directives: {
      'number-only': numberOnlyDirective
    }
  };
  
  export default numberOnlyMixin;
  