import { rejectError } from '@/helpers'
import axiosInstance from '../../services/index'

export default {
    namespaced: true,
    state: {
        createSupport: {},
    },
    getters: {
        getCreateSupport(state) {
            return state.createSupport;
        }
    },
    actions: {
        createSupport({ commit }, data) {
            return axiosInstance.post(`/merchants/support/panel`, data)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCreateSupport', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        }
    },
    mutations: {
        setCreateSupport(state, response) {
            return state.createSupport = response
        }
    }
}
