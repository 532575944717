import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
    theme: {
        primary: '#0F306E',
        secondary: '#444054',
        accent: '#00AD5A',
        error: '#EB4C4C',
        info: '#78C3FB',
        success: '#00AD5A',
        warning: '#00AD5A'
    },
    options: {
        customProperties: true
    },
    iconfont: 'md' || 'mdi',
})
