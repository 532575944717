import axiosInstance from '../../services/index'
import { rejectError } from "@/helpers";

export default {
    namespaced: true,
    state: {
        countries: [],
        currencies: [],
        cryptoCurrencies: [],
        allCryptoCurrencies: [],
        isDisabled: false
    },
    getters: {
        countries(state) {
            return state.countries;
        },
        getAllCurrenciesNofiltered(state){
          return state.currencies;
        },
        currencies(state) {

            let currencies = state.currencies;
            currencies = currencies.map((item) => {
                console.log('item', item)
                return {
                  ...item,
                  icon: `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/currency/${item.code}.svg`,
                };
              });
            if (process.env.NODE_ENV === "development") {
                window.console.log(currencies);
            }

            return currencies;
        },
        getAllCryptoCurrencies(state) {
            return state.allCryptoCurrencies
        },
        cryptoCurrencies(state) {
            let cryptoCurrencies = state.cryptoCurrencies;

            cryptoCurrencies = cryptoCurrencies.map((item) => {
                console.log('item', item)
                return {
                  ...item,
                  icon: `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/coin/${item.code}.svg`,
                };
              });
            return cryptoCurrencies.filter(k => k.isSupported === true);
        }
    },
    actions: {
        getCountries({ commit }, data) {
            return axiosInstance.get(`/contents/country/panel`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCountries', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        getCurrencies({ commit }, data) {
            return axiosInstance.get(`/contents/currency/panel/supported`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCurrencies', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        },
        getCryptoCurrencies({ commit }, data) {
            return axiosInstance.get(`/contents/cryptocurrency/panel/list`)
                .then(res => {
                    const response = res.data;
                    if (response.code === "00" && response.status === "APPROVED") {
                        commit('setCryptoCurrencies', response.data)
                    }
                    else {
                        rejectError("there is an unexpected error")
                    }
                })
                .catch(err => rejectError(err))
        }
    },
    mutations: {
        setCountries(state, countries) {
            return state.countries = countries
        },
        setCurrencies(state, currencies) {
            return state.currencies = currencies
        },
        setCryptoCurrencies(state, cryptoCurrencies) {
            state.allCryptoCurrencies = cryptoCurrencies
            return state.cryptoCurrencies = cryptoCurrencies
        }
    }
}
