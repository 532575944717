var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { height: "auto", color: "default" } },
    [
      _c("v-layout", { attrs: { "justify-center": "", wrap: "" } }, [
        _c(
          "div",
          {
            attrs: {
              "pa-3": "",
              "text-xs-center": "",
              "gray--text": "",
              xs12: "",
            },
          },
          [
            _vm._v(" © Copyright " + _vm._s(new Date().getFullYear()) + "."),
            _c("strong", [
              _vm._v(
                " " + _vm._s(_vm.appCompanyName) + ". All rights reserved."
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }