<template>
  <div :class="headerbg" class="app-header header-shadow">
   <a :href="redirectUrl" class="logo-src">
      <img class="logo-default" :src="logoUrl" height="25px" alt="">
    </a>
    <div class="app-header__content">
      <div class="app-header-left">
        <HeaderBalance />
      </div>
      <div class="app-header-right">
        <UserArea />
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button
          type="button"
          class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }"
          @click="toggleMobile('closed-sidebar-open')"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <!-- <span>
        <b-button
          class="btn-icon btn-icon-only"
          variant="primary"
          size="sm"
          v-bind:class="{ 'active' : isOpenMobileMenu }"
          @click="toggleMobile2('header-menu-open')"
        >
          <div class="btn-icon-wrapper">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </b-button>
      </span> -->
    </div>
  </div>
</template>

<script>
import UserArea from "./header/HeaderUserArea";
import HeaderBalance from "./header/HeaderBalance";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {getAPIURLInfo, getDomainInfoByCache} from "@/helpers";

library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    UserArea,
    HeaderBalance,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isOpenMobileMenu: false,
      logoUrl: null,
      domainInfo: getDomainInfoByCache()
    };
  },
  props: {
    headerbg: String
  },
  methods: {
    toggleMobile(className) {
        const el = document.body;
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            el.classList.add(className);
        } else {
            el.classList.remove(className);
        }
    },
  },

  computed: {
     redirectUrl(){
       return this.redirectMainPageDisabled === true ? "javascript:void(0)" : "/";
     },
     redirectMainPageDisabled(){
        return this.domainInfo ? this.domainInfo.page_config.redirect_main_page_disabled: true;
     },
  },
  created(){
    this.logoUrl = getAPIURLInfo().defaultLogoUrlBlack;
    this.$store.dispatch('content/getCryptoCurrencies')
  }
};
</script>

<style scoped>

  .logo-small-default{
  display: none;
}

.closed-sidebar .logo-small-default{
  display: block;
  width: 35px;
}
.closed-sidebar-open .logo-small-default{
  display: none;
}

.closed-sidebar-open .logo-default{
  display: block;
  height: 30px;
}
</style>
