var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-sidebar sidebar-shadow",
      class: _vm.sidebarbg,
      on: {
        mouseover: function ($event) {
          return _vm.toggleSidebarHover("add", "closed-sidebar-open")
        },
        mouseleave: function ($event) {
          return _vm.toggleSidebarHover("remove", "closed-sidebar-open")
        },
      },
    },
    [
      _c("div", { staticClass: "app-header__logo" }, [
        _c("a", { staticClass: "logo-src", attrs: { href: _vm.redirectUrl } }, [
          _c("img", {
            staticClass: "logo-default",
            attrs: { src: _vm.logoUrl, height: "30px", alt: "" },
          }),
        ]),
        _c("div", { staticClass: "header__pane ml-auto" }, [
          _c("div", { staticClass: "app-header__mobile-menu" }, [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "hamburger close-sidebar-btn hamburger--elastic",
                  class: { "is-active": true },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleMobile("closed-sidebar-open")
                    },
                  },
                },
                [_vm._m(0)]
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "hamburger close-sidebar-btn hamburger--elastic",
              class: { "is-active": _vm.isOpen },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.toggleBodyClass("closed-sidebar")
                },
              },
            },
            [_vm._m(1)]
          ),
        ]),
      ]),
      _vm.showMenu
        ? _c("div", { staticClass: "app-sidebar-content" }, [_vm._m(2)], 1)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "VuePerfectScrollbar",
      { staticClass: "app-sidebar-scroll" },
      [
        _c("sidebar-menu", {
          attrs: { showOneChild: "", menu: _vm.filterMenu(_vm.menu) },
        }),
      ],
      1
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }