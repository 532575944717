import axios from 'axios';

const services = {
  async get(url, params) {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_APPFORM_BITPACE_TOKEN}`
      },
      params,
      validateStatus: status => status < 500
    };

    const { data } = await axios.get(url, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
};

export default services;
