var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.layout !== "auth-layout" && _vm.showSessionModal === true
        ? _c(
            "div",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    "no-close-on-esc": true,
                    "no-close-on-backdrop": true,
                    "hide-header": "",
                    "hide-footer": "",
                  },
                  model: {
                    value: _vm.showSessionModal,
                    callback: function ($$v) {
                      _vm.showSessionModal = $$v
                    },
                    expression: "showSessionModal",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.showAskContiuneSession === true
                        ? _c("LoginConfirmModal", {
                            on: { EndSessionModalShow: _vm.changeModalStatus },
                          })
                        : _c("LogoutModal"),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        _vm.layout,
        { tag: "component" },
        [
          _c("TwoFAModal"),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view", { key: _vm.$route.fullPath })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }